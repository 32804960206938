.highlight {
    z-index: 2;
}

.feedback {
    position: fixed;
    z-index: 2;
    background: #ffffff;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.arrow-line {
    width: 50px;
    border: 0.5px solid #000;
    position: absolute;
    background-color: #000;
    z-index: 3;
}

.arrow-line::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 10px solid #000;
    transform: translateY(-50%);
}

.ant-card-body {
    z-index: 4 !important;
    background-color: white;
}
