body {
    margin: 0;
    padding: 0;
    font-family: "Manrope", sans-serif;
}

.button-width-5px {
    display: inline-block;
    margin: 0px 5px;
}

.list-question-card {
    height: 80vh;
    border-radius: 8px;
}

.create-question-card {
    border-radius: 8px;
}

.edit-question-card {
    border-radius: 8px;
}

.hidden {
    display: none;
}

.logo {
    margin: 16px;
    background: #011529;
}

.site-layout .site-layout-background {
    background: #fff;
}
.equatio-toolbar-wrapper {
    display: block !important;
}

.overflow-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.overflow-wrap-break {
    overflow-wrap: break-word;
}

.full-width {
    width: 100%;
}

.headingToolbar {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    border-bottom: none !important;
}

.latex-insert-form-item {
    margin-bottom: 0px !important;
}

.MuiSvgIcon-root {
    font-size: 18px !important;
}

.tippy-box[data-theme~="tomato"] {
    background-color: tomato;
    color: yellow;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #a9a9a9;
    /* border: 2px solid #555555; */
}

body {
    width: calc(100vw - 5px);
}

.ant-select-item-option-content {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
}

.ant-select-multiple .ant-select-selection-item-content {
    display: inline-block;
    margin-right: 4px;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

.ant-select-selection-item {
    flex: 1 1;
    overflow: hidden;
    font-weight: normal;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    height: auto !important;
}
.react-mic {
    width: 100%;
}
.pause-resume-class {
    margin-right: 10px;
}
.discard-class {
    display: none;
}
.heygen-avatar video {
    width: 95% !important;
    height: 100%;
    object-fit: cover;
}

.heygen-avatar {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e1cf;
}

/* TABLE */
.renderer-table {
    height: auto; /* or height: fit-content; based on specific need */
    /* width: 100%; */
    table-layout: auto;
    /* border: 1px solid #d1d5db; */
    border-collapse: separate;
    border: none;
}

.renderer-table-cell-wrapper {
    background: #fff;
}

.renderer-table-cell {
    display: inline-flex;
    min-width: 64px;
    max-width: 280px;
    min-height: 64px;
    padding: 4px;
    align-items: center;
    align-content: center;
    gap: 0px 4px;
    flex-wrap: wrap;
    /* background:#94a3b8; */
    inline-size: max-content;
}

.renderer-table-cell .renderer-table-cell-content-text {
    color: #000;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
    max-width: 100%; /* Makes the text's max-width flexible to the container's width */
    inline-size: auto;
    overflow-wrap: break-word;
}

.renderer-table-text-wrapper {
    display: flex;
    min-width: 20px;
    max-width: 100%;
    padding: 0px 4px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.renderer-table-latex-wrapper {
    display: flex;
    max-width: 100%;
    min-height: 64px;
    padding: 11px 4px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.renderer-table-input-wrapper {
    display: flex;
    max-width: 300px;
    /* padding: 8px 4px; */
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
}

.renderer-table-dropdown-wrapper {
    display: flex;
    min-width: 56px;
    max-width: 300px;
    min-height: 56px;
    padding: 4px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
    background: #fff;
}

.renderer-table-dropdown-item {
    display: flex;
    /* width: 84px; */
    min-width: 56px;
    max-width: 300px;
    min-height: 56px;
    padding: 4px 8px;
    align-items: center;
    align-content: center;
    gap: 4px;
    flex-wrap: wrap;
    border-radius: 2px;
    border: 0.5px solid #4f4f4f;
    background: #fcfbfb;
    cursor: pointer;
}

.renderer-table-dropdown-text {
    max-width: 280px;
    color: #828282;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 200;
    line-height: 150%; /* 24px */
    inline-size: auto;
    overflow-wrap: break-word;
}

.renderer-table-image-wrapper {
    display: flex;
    min-width: 64px;
    max-width: 260px;
    padding: 8px 4px;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
    pointer-events: none;
}

.renderer-table-image-item {
    display: flex;
    min-width: 48px;
    max-width: 300px;
    min-height: 48px;
    padding: 4px 8px;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
}

.renderer-table-drag-drop-area-wrapper {
    display: flex;
    width: fit-content;
    height: fit-content;
    min-width: 64px;
    max-width: 300px;
    min-height: 56px;
    padding: 8px 4px;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 0.5px solid #e0e0e0;
    background: #fcfbfb;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset;
}

.renderer-table-dropped-item-wrapper {
    display: flex;
    min-width: 56px;
    min-height: 56px;
    padding: 4px;
    height: fit-content;
    width: fit-content;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px 10px;
    flex-wrap: wrap;
    border-radius: 4px;
    border: 0.5px solid #4f4f4f;
    background-color: lightgray;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1) inset;
    cursor: pointer;
    background-size: 56px;
}

.renderer-table-dropped-item-text {
    color: #000;
    text-align: center;
    font-family: Epilogue;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */
}

.renderer-table-dropped-item-wrapper > img {
    width: 48px;
}
/* TABLE */

/* TAILWIND */
.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

/* TAILWIND */

.item-rows td {
    border: 1px solid #123123;
}
